<template>
	<section class="section wow">
		<div class="content">
			<div
				v-if="vacancy"
				class="vacancy-page-block"
			>
				<h1
					id="seo-title"
					class="seo-title"
				>
					Вакансия {{ vacancy.name }}
				</h1>
				<Breadcrumb :page-name="vacancy.name" />
				<div
					v-if="isLoading"
					class="preloader"
					style="margin-top: 100px;"
				/>
				<div
					v-else
					class="grid"
				>
					<div class="box">
						<h3 class="page-title">
							{{ vacancy.name }}
						</h3>
						<div class="vacancy-tag-group">
							<div class="vacancy-tag-item">
								{{ typeEmploymentFormat(vacancy.type_employment) }}
							</div>
							<div
								v-if="vacancy.city"
								class="vacancy-tag-item"
							>
								{{ vacancy.city }}
							</div>
						</div>

						<div
							v-if="isLoading"
							class="preloader"
							style="margin-top: 200px"
						/>
						<template v-else>
							<div class="vacancy-block-header">
								<router-link
									:to="`/company/${vacancy.company.id}`"
									class="vacancy-page-title"
								>
									{{ vacancy.company.company_name }}
								</router-link>
								<span
									class="vacancy-page-subtitle"
								>
									{{ vacancy.company.company_real_name }}
								</span>
								<div
									v-if="vacancy.salary_from || vacancy.salary_before"
									class="vacancy-page-salary"
								>
									{{ culcSalary(vacancy.salary_from, vacancy.salary_before) }}
								</div>
								<div
									class="vacancy-page-city"
								>
									<p>{{ addressFormat(vacancy.city, vacancy.street, vacancy.house, vacancy.house_building, vacancy.apartment) }}</p>
								</div>
								<div
									v-if="!isRecruiter"
									class="vacancy-page-action"
								>
									<div class="cells">
										<div class="cell">
											<BaseButton
												id="btnRespondToVacancy"
												class="button full"
												orange
												@clickButton="eventYandexRespond(vacancy)"
											>
												Откликнуться
											</BaseButton>
										</div>
										<div class="cell">
											<BaseButton
												v-if="isFastResponse"
												light-border
												transparent
												class="button-hollow vacancy-action__button full"
												@clickButton="eventYandexFastRespond(vacancy)"
											>
												Быстрый отклик
											</BaseButton>
											<div
												v-else
												class="button-hollow button-preloader"
											>
												Быстрый отклик
												<div class="button-preloader-icons">
													<div class="button-preloader-icon" />
													<div class="button-preloader-icon" />
													<div class="button-preloader-icon" />
												</div>
											</div>
										</div>
										<div class="cell">
											<button
												v-if="isLogged"
												class="like-item"
												:class="{active:isFavorite}"
												@click="addFavoriteVacancy(vacancy.id)"
											>
												<div class="like-icon" />
												<div
													v-if="isFavorite"
													class="like-title"
												>
													В избранном
												</div>
												<div
													v-else
													class="like-title"
												>
													В избранное
												</div>
											</button>
										</div>
									</div>
								</div>
							</div>
						</template>
						<div class="vacancy-page-text">
							<div class="text">
								<div class="text-inner-start">
									<p
										v-if="vacancy.description"
										style="word-break: break-word; white-space: pre-line;"
									>
										{{ vacancy.description }}
									</p>
								</div>
								<br>
								<p><b>Обязанности:</b></p>
								<div
									v-if="vacancy.duties"
									class="text-inner-list"
									style="word-break: break-word; white-space: pre-line;"
								>
									{{ vacancy.duties }}
								</div>
								<br>
								<p><b>Требования:</b></p>
								<div
									v-if="vacancy.requirements"
									class="text-inner-list"
									style="word-break: break-word; white-space: pre-line;"
								>
									{{ vacancy.requirements }}
								</div>
								<br>
								<p><b>Условия:</b></p>
								<div
									v-if="vacancy.conditions"
									class="text-inner-list"
									style="word-break: break-word; white-space: pre-line;"
								>
									{{ vacancy.conditions }}
								</div>
							</div>
							<router-link
								class="button-hollow"
								:to="`/company/${vacancy.company.id}?vacancies=true`"
							>
								Другие вакансии этой компании
							</router-link>
						</div>
					</div>
					<div
						v-if="!isLoading"
						class="box"
					>
						<div class="vacancy-sidebar-block">
							<h3 class="block-title">
								Другие вакансии
							</h3>
							<ul class="vacancy-card-list">
								<BaseCardVacancies
									v-for="vacancy in first3Vacancies"
									:key="vacancy.id"
									:is-favorite="isFavorited(vacancy.id)"
									:is-favorite-vacancy="isFavorited(vacancy.id)"
									is-position-sidebar
									:vacancy="vacancy"
									class="vacancy-card-item"
								/>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'
import Breadcrumb from '@/components/Breadcrumb';
import BaseButton from '@/components/BaseComponents/BaseButton.vue'
import BaseCardVacancies from '@/components/BaseComponents/BaseCardVacancies'

export default {
    components: {
        Breadcrumb,
        BaseButton,
        BaseCardVacancies,
    },
    data: () => ({
        vacancy: null,
        isVacancyLoading: true,
        isVacanciesLoading: true
    }),
    computed: {
        ...mapGetters('tb', {
            vacancies: 'getAllVacancies'
        }),
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            isRecruiter: 'isRecruiter',
            user: 'userInfo'
        }),
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            favoriteVacancies: 'favoriteVacancies'
        }),
        ...mapGetters('cnst', {
            employments: 'getEmployments'
        }),
        favoritesVacanciesId() {
            return this.favoriteVacancies.map((el) => {
                return el.id
            })
        },
        isFavorite() {
            return this.favoritesVacanciesId.includes(this.vacancy.id)
        },
        urlVacancyId() {
            return +this.$route.params.id
        },
        companyLogoImg() {
            return this.vacancy && this.vacancy.company.image_company && this.vacancy.company.image_company.find(image => image.type === 'company_logo')
        },
        first3Vacancies() {
            return this.vacancies && this.vacancies.filter(x => x.id !== this.urlVacancyId).slice(0, 3)
        },
        isFastResponse() {
            return this.isLogged && !!this.user || !this.isLogged
        },
        isLoading() {
            return this.isVacancyLoading || this.isVacanciesLoading
        }
    },
    watch: {
        $route() {
            this.isVacancyLoading = true
            this.getVacancy(this.urlVacancyId)
                .then(result => this.vacancy = result)
                .finally(() => this.isVacancyLoading = false)
        }
    },
    created() {
        if (this.vacancies) this.isVacanciesLoading = false
        else this.getAllVacancies().finally(() => this.isVacanciesLoading = false)

        this.getVacancy(this.urlVacancyId)
            .then(result => {
                this.vacancy = result
                document.getElementById('seo-title').textContent = result.name
                document.title = `Вакансия ${result.name} ${result.city ? result.city : ''}`
                document.head.querySelector('meta[name="description"]').setAttribute('content', `Вакансия ${result.name} в компании ${result.company.company_name} ${result.city ? result.city : ''}`)
                document.head.querySelector('meta[property="og:description"]').setAttribute('content', `Вакансия ${result.name} в компании ${result.company.company_name} ${result.city ? result.city : ''}`)
            })
            .finally(() => this.isVacancyLoading = false)
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
        if(this.isLogged) {
            this.$store.dispatch('user/getFavoriteVacancies')
        }
    },
    methods: {
        ...mapActions('tb', [
            'getAllVacancies',
            'getVacancy'
        ]),
        ...mapActions('user', ['addFavoriteVacancy']),
        isFavorited(id) {
            return this.favoritesVacanciesId.includes(id)
        },
        typeEmploymentFormat(type) {
            return this.employments.find(x => x.slug === type).value
        },
        culcSalary(from, before) {
            let intl = new Intl.NumberFormat('en-En')
            return (from ? `от ${intl.format(from)} ₽ ` : '') + (before ? `до ${intl.format(before)} ₽` : '');
        },
        eventYandexRespond(vacancy) {
            if(this.$metrika && (window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru')) {
                this.$metrika.reachGoal('click_response_vacancy', {
                    URL: document.location.href
                })
            }
            if (this.isLogged) {
                this.isCheckingResponse = true
                if (this.$store.state.user.responses) {
                    if (this.checkLimitResponses(this.$store.state.user.responses, vacancy)) this.modalOpen('reqVacancy', vacancy)
                    else this.modalOpen('stdFail', 'Мы уже получили от тебя отклик на эту вакансию')
                    this.isCheckingResponse = false
                }
                else {
                    this.$store.dispatch('user/getAllResponses').then(result => {
                        if (this.checkLimitResponses(result, vacancy)) this.modalOpen('reqVacancy', vacancy)
                        else this.modalOpen('stdFail', 'Мы уже получили от тебя отклик на эту вакансию')
                        this.isCheckingResponse = false
                    })
                }
            } else {
                this.modalOpen('signIn')
            }
        },
        eventYandexFastRespond(vacancy) {
            if(this.$metrika && (window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru')) {
                this.$metrika.reachGoal('click_fast_response_vacancy', {
                    URL: document.location.href
                })
            }
            if (this.isLogged) {
                this.isCheckingFastResponse = true
                if (this.$store.state.user.responses) {
                    if (this.checkLimitResponses(this.$store.state.user.responses, vacancy)) this.modalOpen('fastVacancy', vacancy)
                    else this.modalOpen('stdFail', 'Мы уже получили от тебя отклик на эту вакансию')
                    this.isCheckingFastResponse = false
                }
                else {
                    this.$store.dispatch('user/getAllResponses').then(result => {
                        if (this.checkLimitResponses(result, vacancy)) this.modalOpen('fastVacancy', vacancy)
                        else this.modalOpen('stdFail', 'Мы уже получили от тебя отклик на эту вакансию')
                        this.isCheckingFastResponse = false
                    })
                }
            } else {
                this.modalOpen('fastVacancy', {vacancy: vacancy})
            }
        },
        eventYandexRespondOtherVacancy(vacancy) {
            if(this.$metrika && (window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru')) {
                this.$metrika.reachGoal('click_response_vacancy', {
                    URL: document.location.href
                })
            }
            if (this.isLogged) {
                this.isCheckingResponse = true
                if (this.$store.state.user.responses) {
                    if (this.checkLimitResponses(this.$store.state.user.responses, vacancy)) this.modalOpen('reqVacancy', vacancy)
                    else this.modalOpen('stdFail', 'Мы уже получили от тебя отклик на эту вакансию')
                    this.isCheckingResponse = false
                }
                else {
                    this.$store.dispatch('user/getAllResponses').then(result => {
                        if (this.checkLimitResponses(result, vacancy)) this.modalOpen('reqVacancy', vacancy)
                        else this.modalOpen('stdFail', 'Мы уже получили от тебя отклик на эту вакансию')
                        this.isCheckingResponse = false
                    })
                }
            } else {
                this.modalOpen('signIn')
            }
        },
        checkLimitResponses(responses, vacancy) {
            // FIXME: заменить на значение из глобальных настроек сайта
            const limit = 1
            const totalResponse = responses.reduce((accum, current) => {
                if (current.vacancy.id === vacancy.id) {
                    accum += 1
                    return accum
                }
                else return accum
            }, 0)
            return totalResponse < limit
        }
    }
}
</script>

<style lang="less">
@import "../assets/styles/pages/vacancy";
</style>
